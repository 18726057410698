import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../api/axios";
import "./dashboard.css";
import DashboardProgress from "./dashboardProgress";
import DashboardButton from "./dashboardButton";
import Confetti from "react-confetti";

const DashboardTitles = ({ state, hackathonStatus }) => {
  if (hackathonStatus === "not_open") {
    return "Applications Are Not Open Yet";
  } else if (hackathonStatus === "open") {
    if (state === "started" || state === "unsubmitted") {
      return `Continue Your Application Now`;
    } else {
      return "Your Application is Submitted!";
    }
  } else if (state === "started" || state === "unsubmitted")
    return "Applications Are closed";
  else if (hackathonStatus === "closed")
    return "Your Application is Being Reviewed";
  else if (state === "submitted" || state === "rejected")
    return "Unfortunately You Have Been Rejected";
  else if (state === "declined")
    return "We're Sorry You Cannot Attend HackPrinceton!";
  else if (state === "confirmed") return "You're All Set!";
  else if (
    hackathonStatus !== "second_confirmation_closed" &&
    hackathonStatus !== "waitlist_closed" &&
    state === "waitlisted"
  )
    return "You Have Been Waitlisted";
  else if (
    hackathonStatus !== "second_confirmation_closed" &&
    state === "waitlist_confirmed"
  )
    return "You are on the Waitlist!";
  else if (
    hackathonStatus !== "second_confirmation_closed" &&
    state === "waitlist_accepted"
  )
    return "Congratulations, You Have Been Accepted!";
  else if (hackathonStatus === "released" && state === "accepted")
    return "Congratulations, You Have Been Accepted!";
  else if (state === "accepted" || state === "waitlist_accepted")
    return "Unfortunately the Confirmation Date has Passed";
  else if (state === "waitlist_confirmed" || state === "waitlisted")
    return "Unfortunately You Have Been Rejected";
  else return "";

  // if (hackathonStatus === "not_open") {
  //   return "Applications Are Not Open Yet";
  // }

  // if (
  //   (state === "unsubmitted" || state === "started") &&
  //   hackathonStatus !== "open"
  // )
  //   return "Applications Are closed";

  // switch (state) {
  //   case "unsubmitted":
  //     return "Start Your Application Now!";
  //   case "started":
  //     return "Continue Your Application Now";
  //   case "submitted":
  //     return "Your Application is Submitted!";
  //   case "waitlisted":
  //     return "You Have Been Waitlisted";
  //   case "waitlist_confirmed":
  //     return "You are on the waitlist!";
  //   case "accepted":
  //     return "Congratulations, You Have Been Accepted!";
  //   case "waitlist_accepted":
  //     return "Congratulations, You Have Been Accepted!";
  //   case "confirmed":
  //     return "You're All Set!";
  //   case "rejected":
  //     return "Unfortunately You Have Been Rejected";
  //   case "declined":
  //     return "We're Sorry You Cannot Attend HackPrinceton!";
  //   default:
  //     return "";
  // }
};

const DashboardMessages = ({ state, config, hackathonStatus }) => {
  // Default dates for fallback
  let dueDate = "2024-05-20";
  let startDate = "2024-05-21";
  let waitlistDueDate = "2024-05-22";
  let waitlistAcceptDate = "2024-05-22";
  let regularConfirmDate = "2024-05-22";

  // Check if config is properly loaded and contains the required dates
  if (config && config !== "Loading" && config.dates) {
    dueDate = new Date(config.dates.due + "T00:00:00Z");
    startDate = new Date(config.dates.start + "T00:00:00Z");
    waitlistDueDate = new Date(config.dates.waitlist_deadline + "T00:00:00Z");
    waitlistAcceptDate = new Date(
      config.dates.confirmation_first_deadline + "T00:00:00Z"
    );
    regularConfirmDate = new Date(
      config.dates.confirmation_second_deadline + "T00:00:00Z"
    );
  } else {
    dueDate = new Date(dueDate + "T00:00:00Z");
    startDate = new Date(startDate + "T00:00:00Z");
    waitlistDueDate = new Date(waitlistDueDate + "T00:00:00Z");
    waitlistAcceptDate = new Date(waitlistAcceptDate + "T00:00:00Z");
    regularConfirmDate = new Date(waitlistAcceptDate + "T00:00:00Z");
  }

  const formattedDueDate = dueDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  const formattedStartDate = startDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  const formattedWaitlistDueDate = waitlistDueDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  const formattedRegularDue = regularConfirmDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });

  const formattedWaitlistAccept = waitlistAcceptDate.toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    }
  );

  if (hackathonStatus === "not_open") {
    return "Applications for this semester are not yet open. Check back soon to apply!";
  } else if (hackathonStatus === "open") {
    if (state === "started" || state === "unsubmitted") {
      return `Get started with your HackPrinceton journey! The first step towards joining HackPrinceton is submitted your application below. Click launch application to finish your application due ${formattedDueDate}.`;
    } else {
      return "Congratulations, your application has been submitted! We look forward to reviewing your application and will inform you whether you have been accepted soon. In the meantime, feel free to edit your aplication below.";
    }
  } else if (state === "started" || state === "unsubmitted")
    return "Applications are closed for this semester. Check back next year to apply!";
  else if (hackathonStatus === "closed")
    return "Your application has been submitted! We look forward to reviewing your application and will inform you whether you have been accepted soon. ";
  else if (state === "submitted" || state === "rejected")
    return "The organizing team was fortunate enough to receive many talented applicants for this year's cycle. Unfortunately we could not offer you a spot in this year's hackathon but we truly appreciate the effort in your application and hope you will reapply next semester!";
  else if (state === "declined")
    return "We're so sorry you can't make it! Please feel free to apply in future semesters and we hope to see you soon.";
  else if (state === "confirmed")
    return `Thank you for your confirmation, we're so excited to have you at HackPrinceton this semester! We look forward to see you at ${config.location} on ${formattedStartDate}`;
  else if (
    hackathonStatus !== "second_confirmation_closed" &&
    hackathonStatus !== "waitlist_closed" &&
    state === "waitlisted"
  )
    return `The organizing team was fortunate enough to receive many talented applicants for this year's cycle. While we cannot offer you an initial acceptance, please fill out the form below by ${formattedWaitlistDueDate} to join the waitlist and still potentially join HackPrinceton!`;
  else if (
    hackathonStatus !== "second_confirmation_closed" &&
    state === "waitlist_confirmed"
  )
    return "Thank you for joining the waitlist! We will let you know as soon as possible whether you have been accepted into HackPrinceton.";
  else if (
    hackathonStatus !== "second_confirmation_closed" &&
    state === "waitlist_accepted"
  )
    return `Congratulations on being accepted to HackPrinceton! The next step in your HackPrinceton journey is confirming that you will attend. Please confirm below by ${formattedWaitlistAccept}!`;
  else if (hackathonStatus === "released" && state === "accepted")
    return `Congratulations on being accepted to HackPrinceton! The next step in your HackPrinceton journey is confirming that you will attend. Please confirm below by ${formattedRegularDue}!`;
  else if (state === "accepted" || state === "waitlist_accepted")
    return "Unfortunately the date to confirm your attendance for the hackathon has passed so we cannot have you at this year's event. We hope you will reapply in the future!";
  else if (state === "waitlist_confirmed" || state === "waitlisted")
    return "The organizing team was fortunate enough to receive many talented applicants for this year's cycle. Unfortunately we could not offer you a spot in this year's hackathon but we truly appreciate the effort in your application and hope you will reapply next semester!";
  else return "";

  // if ((state === "unsubmitted" || state === "started") && hackathonStatus !== "open")
  //   return "Unfortunately applications are closed for this semester. Check back next semester to apply!";

  // switch (state) {
  //   case "unsubmitted":
  //     return `Get started with your HackPrinceton journey! The first step towards joining HackPrinceton is beginning your application below. Click launch application to begin your application due ${formattedDueDate}.`;
  //   case "started":
  //     return `Get started with your HackPrinceton journey! The first step towards joining HackPrinceton is submitted your application below. Click launch application to finish your application due ${formattedDueDate}.`;
  //   case "submitted":
  //     return "Congratulations, your application has been submitted! We look forward to reviewing your application and will inform you whether you have been accepted soon. In the meantime, feel free to edit your aplication below.";
  //   case "waitlisted":
  //     return `The organizing team was fortunate enough to receive many talented applicants for this year's cycle. While we cannot offer you an initial acceptance, please fill out the form below by ${formattedWaitlistDueDate} to join the waitlist and still potentially join HackPrinceton!`;
  //   case "waitlist_confirmed":
  //     return "Thank you for joining the waitlist! We will let you know as soon as possible whether you have been accepted into HackPrinceton.";
  //   case "accepted":
  //     return `Congratulations on being accepted to HackPrinceton! The next step in your HackPrinceton journey is confirming that you will attend. Please confirm below by ${formattedRegularDue}!`;
  //   case "waitlist_accepted":
  //     return `Congratulations on being accepted to HackPrinceton! The next step in your HackPrinceton journey is confirming that you will attend. Please confirm below by ${formattedWaitlistAccept}!`;
  //   case "confirmed":
  //     return `Thank you for your confirmation, we're so excited to have you at HackPrinceton this semester! We look forward to see you at ${config.location} on ${formattedStartDate}`;
  //   case "rejected":
  //     return "The organizing team was fortunate enough to receive many talented applicants for this year's cycle. Unfortunately we could not offer you a spot in this year's hackathon but we truly appreciate the effort in your application and hope you will reapply next semester!";
  //   case "declined":
  //     return "We're so sorry you can't make it! Please feel free to apply in future semesters and we hope to see you soon.";
  //   default:
  //     return "";
  // }
};

const Dashboard = () => {
  const { authState } = useAuth();
  const [email, setEmail] = useState("Loading");
  const [status, setStatus] = useState("Loading");
  const [config, setConfig] = useState("Loading");
  const [numberOfPieces, setNumberOfPieces] = useState(0);
  const [hackathonStatus, setHackathonStatus] = useState("Loading");

  useEffect(() => {
    setEmail(authState.user.email);
  }, []);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get("/api/applications/status");
        setStatus(response.data["status"]);
      } catch (err) {}
    };

    const fetchConfig = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/applications/cycle/config"
        );
        // console.log(response.data);
        setConfig(response.data);
      } catch (err) {
        // console.log(err);
      }
    };

    const fetchHackathonStatus = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/applications/cycle/status"
        );
        // console.log(response);
        setHackathonStatus(response.data);
      } catch (err) {
        // console.log(err);
      }
    };

    if (authState.isAuthenticated) {
      fetchStatus();
      fetchConfig();
      fetchHackathonStatus();
    }
  }, []);

  useEffect(() => {
    if (status === "accepted" || status === "confirmed") {
      setNumberOfPieces(200);
      const timer = setTimeout(() => {
        setNumberOfPieces(0);
      }, 2000); // Hide confetti after 2 seconds
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [status]);

  return (
    <div className="dashboard">
      <div className="row">
        <DashboardProgress status={status} />
      </div>

      <div className="row-dashboard-main">
        <div className="medium-3-columns">
          <h5 className="status-header">Your Info</h5>
          <p>{email}</p>
          <h5 className="status-header">Status</h5>
          <p>
            {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
          </p>
        </div>

        <div className="medium-9-columns">
          <h2 className="status">
            <DashboardTitles state={status} hackathonStatus={hackathonStatus} />
          </h2>
          {/* <h2 className="status">{DashboardTitles[status] || ""}</h2> */}
          {hackathonStatus === "released" && (
            <Confetti numberOfPieces={numberOfPieces} />
          )}
          {(
            <p>
              <DashboardMessages
                state={status}
                hackathonStatus={hackathonStatus}
                config={config}
              />
            </p>
          ) || ""}

          <DashboardButton status={status} email={email} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
