import "./viewUser.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../../../../hooks/useAuth";
import axiosInstance from "../../../../api/axios";
import ApplicationForm from "../../../apply/applicationForm";

const ViewUser = () => {
  const { email } = useParams();
  const { authState } = useAuth();
  const [application, setApplication] = useState({});
  const [acceptOrDecline, setAD] = useState("");
  const [priorStatus, setPriorStatus] = useState("");

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.get(
        `/api/resume/download/${email}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${email}_resume.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  };

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/applications/status/${email}`,
          email
        );
        setAD(response.data["status"]);
        setPriorStatus(response.data["status"]);
      } catch (err) {
        // console.log(err);
      }
    };

    const fetchApplication = async () => {
      try {
        const response = await axiosInstance.get(`/api/applications/${email}`);
        setApplication(response.data);
      } catch (err) {
        // console.log(err);
      }
    };

    if (authState.isAuthenticated) {
      fetchApplication();
      fetchStatus();
    }
  }, []);

  const handleSelectChange = (event) => {
    setAD(event.target.value);
  };

  const handleSubmitStatus = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post(
        `/api/applications/status/${email}`,
        { status: acceptOrDecline }
      );
      // console.log(response);
      window.location.reload(true);
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <div>
      <div className="dashboard">
        <div className="title-div">
          <div>
            <h1 className="user-dash-title">
              User: {application.first} {application.last}
            </h1>
            <p>Current Status: {priorStatus}</p>
            <button className="user-dash-b">
              <a href="/admin/users">Return to Users</a>
            </button>
          </div>
        </div>

        <div className="title-div">
          <div className="left-div">
            <h1 className="user-dash-title">Tools</h1>
          </div>
          <div className="right-div">
            <div>
              <label className="label-vu">Accept / Reject Hacker</label>

              <form onSubmit={handleSubmitStatus}>
                <select
                  className="confirm-deadline"
                  name="ad"
                  type="date"
                  value={acceptOrDecline}
                  onChange={handleSelectChange}
                >
                  <option value="submitted">No Decision</option>
                  <option value="accepted">Accepted</option>
                  <option value="waitlisted">Waitlisted</option>
                  <option value="rejected">Rejected</option>
                  <option value="waitlist_accepted">Waitlist Accepted</option>
                </select>
                <button type="submit" className="user-dash-b">
                  Save Decision
                </button>
              </form>
            </div>
            <div>
              <label className="label-vu">Download User's Resume</label>
              <button
                onClick={handleDownload}
                className="button-vu"
                type="submit"
              >
                Download Resume
              </button>
            </div>
          </div>
        </div>

        <div className="title-div">
          <div className="left-div">
            <h1 className="user-dash-title">Confirmation Details</h1>
          </div>
          <div className="right-div">
            <div>
              <h2 className="contact-info-h1">Contact</h2>
              <p className="email-p">Email: {email}</p>
            </div>
          </div>
        </div>

        {/* <div className="title-div">
          <div className="left-div">
            <h1 className="user-dash-title">Score User</h1>
          </div>
          <div className="right-div">
            <div>
              <form className="ad-vu-form">
                <input
                  type="numeric"
                  className="ad-vu-enterscore"
                  placeholder="Enter Score"
                />
                <button type="submit" className="user-dash-b">
                  Submit Score
                </button>
              </form>
            </div>
          </div>
          <div>
            <h2 className="ad-vu-scores-title">Current Scores</h2>
            <ul className="ad-vu-list">
              <li className="ad-vu-score">Mark: 6</li>
              <li className="ad-vu-score">Mark: 6</li>
              <li className="ad-vu-score">Mark: 6</li>
            </ul>
          </div>
          <div className="ad-vu-averagescore">
            <h2 className="ad-vu-scores-title">Average Score</h2>
            <p className="ad-vu-average-score-p">6.0</p>
          </div>
        </div> */}

        <div className="bottom-div">
          <h1 className="user-dash-title-bd">Application Details</h1>
          <ApplicationForm email={email} />
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
